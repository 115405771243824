<template>
  <b-card>
    <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app" centered
      title="Expired !" @ok="gotologin()">
      <b-card-text>Session Expired</b-card-text>
    </b-modal>
    <b-form @submit.prevent="saveAllApps()">
      <b-container>
        <b-row class="justify-content-md-center">
          <b-col cols="1" md="2">
            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="flat-warning" class="btn-icon"
              to="/myprofile/accounts" v-b-tooltip.hover title="Go Back">
              <feather-icon icon="ArrowLeftCircleIcon" size="20" />
            </b-button>
          </b-col>
          <b-col class="col-md-7 col-sm-12">
            <h1>Account's Applications</h1>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-3" v-for="app in apps" :key="app.appId">
            <div class="card w-100 h-100">
              <img :src="app.icon" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title my-2 text-overflow">{{ app.title }}</h5>
                <p class="card-text text-ellipsis">
                  {{ app.summary }}
                </p>
              </div>
              <div class="card-footer text-center">
                <a :href="'https://play.google.com/store/apps/details?id=' + app.appId
                " target="_blank" class="btn btn-secondary">Visit On Store</a>
              </div>
            </div>
          </div>
        </b-row>
      </b-container>
    </b-form>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import axios from "@axios";
import router from "@/router";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { connectApi } from "@/helper/connectApi";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      account_name: null,
      apps: [],
      token: null,
      server: 1,
    };
  },
  created() { },
  mounted() {
    this.getSelectedAccount();
    this.token = JSON.parse(localStorage.getItem("userAccess")).token;
    this.server = JSON.parse(localStorage.getItem("userAccess")).server;
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },

    getSelectedAccount() {
      axios
        .get(`/get-account/${this.$route.params.id}`)
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            router.push({ name: "auth-login" });
          }

          // axios
          //   .get(
          //     `https://sc${this.server}.clicklab.app/api/developers/${isNaN(data.account.account_id)
          //       ? data.account.account_name
          //       : data.account.account_id
          //     }?fullDetail=true`
          //   )
          const response = connectApi(`https://sc${this.server}.clicklab.app/api/developers/${isNaN(data.account.account_id)
            ? data.account.account_name
            : data.account.account_id
            }?fullDetail=true`);
          response
            .then((response) => {
              const { status } = response.data;
              if (status === "Unauthorized") {
                removeLocalStorageItems();
                this.$root.$emit("bv::show::modal", "modal-session-app");
                return;
              }
              const data = response.data;
              return data;
            })
            .then((data) => {
              this.apps = [];
              data.apps.forEach((app) => {
                this.apps.push(app);
              });
            })
            .catch((error) => {
              this.showToast(
                "danger",
                "AlertOctagonIcon",
                "Error",
                "There's no application associated to this account!"
              );

              document.getElementById("apps-content").innerHTML =
                "There's no application associated to this account!";
            });
        })
        .catch((error) => console.error(error));
    },
    gotologin() {
      this.$router.push({ name: "auth-login" });
    },
    saveAllApps() {
      axios
        .post("/save-account-apps", {
          apps: this.apps,
          account_id: this.$route.params.id,
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          }
          if (data.status === "success") {
            if (data.apps.length > 0) {
              router.push({ name: "apps" });
            } else {
              this.showToast(
                "warning",
                "AlertOctagonIcon",
                "Warning",
                "All Those application Already Exist!"
              );
            }
          } else {
            this.showToast(
              "danger",
              "AlertOctagonIcon",
              "Error",
              "Try Again!"
            );
          }
        })
        .catch(console.error);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-ellipsis {
  display: block;
  display: -webkit-box;
  height: 63px;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
